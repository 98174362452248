import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { SideFiltersService } from '../../../services/side-filters.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, filter } from 'rxjs';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterTypes } from '../../../enums/filterTypes';
import { ObjectTypes } from '../../../enums/objectTypes';
import { ObjectEventEmitters } from '../../../events/object.events';
import { Account } from '../../../models/account';
import { Constants } from '../../../models/constants';
import { Employee } from '../../../models/employee';
import { EmployeeCustomFilterViewModel } from '../../../models/filter/employeeCustomFilterViewModel';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { CustomFiltersService } from '../../../services/custom-filters.service';
import { HeaderFiltersCachingService } from '../../../services/header-filters-caching.service';
import { HeaderFiltersTypeService } from '../../../services/header-filters-type.service';
import { HeaderFiltersService } from '../../../services/header-filters.service';
import { LocalisationService } from '../../../services/localisation.service';
import { ModuleService } from '../../../services/module.service';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { FilterActionTypes } from '../../../enums/filter/filterActionTypes.enum';
import { TitleOnlyModalComponent } from 'src/app/modules/settings/components/common/modals/title-only/title-only-modal.component';
import { BsModalConfig } from '../../../config/modal.config';
import { AlertService } from '../../../services/alert.service';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-side-filters',
  templateUrl: './side-filters.component.html',
  styleUrls: ['./side-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideFiltersComponent implements OnInit {
  @HostBinding('style.visibility') get visibility() { return this.visible ? 'visible': 'hidden'; }
  public isFiltersLoading: boolean = true;

  private favouritesFiltersModalRef: BsModalRef;

  employee: Employee;
  account: Account;
  filters: FilterViewModel[] = [];
  appliedFilters: FilterViewModel[] = [];
  favoriteFilters: EmployeeCustomFilterViewModel[] = [];
  suggestedFilters: EmployeeCustomFilterViewModel[] = [];
  filterTypeSelectorViewModels: FilterTypeSelectorViewModel[] = [];
  filterTypesEnum = FilterTypes;
  private readonly subscriptions = new Subscription();
  showOnlyActive: boolean = false;
  mobile: boolean;
  isIOS: boolean;
  isAndroid: boolean;

  desktopFiltersAddFavoriteContainerVisible: boolean = false;
  mobileFiltersAddFavoriteContainerVisible: boolean = false;
  currentObjectTypes: ObjectTypes[] = [];
  filteredAppliedFilters = new Map<string, FilterViewModel[]>();
  filteredTagsFilters = new Map<string, FilterViewModel[]>();
  public readonly T = T;
  public visible = false;
  public archiveFilterSelectorVM: FilterTypeSelectorViewModel;
  public generalFilterSelectorVMs: FilterTypeSelectorViewModel[] = [];
  public dateFiltersSelectorVMs: FilterTypeSelectorViewModel[] = [];
  public tagGroupFilters: FilterViewModel[] = [];
  public filteredByTextGeneralFiltersSelectorVMs: FilterTypeSelectorViewModel[] = [];
  public filteredByTextTagGroupFilters: FilterViewModel[] = [];
  public filteredByTextDateFiltersSelectorVMs: FilterTypeSelectorViewModel[] = [];
  public searchTextForFilterSelectors: string = '';
  public expandedFilterSelectorId = '';
  public favouriteMatchingFilter: EmployeeCustomFilterViewModel;
  public archivedToggleOptions = [
    { value: 'Open', displayText: this.translateService.instant(T.common.open.many), showArchived: false },
    { value: 'Archived', displayText: this.translateService.instant(T.common.archived.many), showArchived: true },
  ];

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly authenticationService: AuthenticationService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly headerFiltersService: HeaderFiltersService,
    private readonly headerFiltersTypeService: HeaderFiltersTypeService,
    private readonly customFiltersService: CustomFiltersService,
    private readonly moduleService: ModuleService,
    private readonly objectEventEmitters: ObjectEventEmitters,
    private readonly localisationService: LocalisationService,
    private readonly bsModalService: BsModalService,
    private readonly headerFiltersCachingService: HeaderFiltersCachingService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly sideFiltersService: SideFiltersService,
    private readonly modalConfig: BsModalConfig,
    private readonly alertService: AlertService
  ) {}

  @HostListener('window:resize', ['$event']) onWindowResize(event) {
    this.mobile = event.target.innerWidth <= Constants.xs;
  }

  ngOnInit() {
    this.mobile = window.innerWidth <= Constants.xs;
    this.isIOS = Capacitor.getPlatform() === 'ios';
    this.isAndroid = Capacitor.getPlatform() === 'android';
    this.employee = this.authenticationService.getCurrentEmployee();
    this.account = this.authenticationService.getCurrentAccount();

    this.currentObjectTypes = this.moduleService.currentObjectTypes;
    this.sideFiltersService.initFilterSelectors().subscribe();

    if (this.currentObjectTypes.length) {
      this.showOnlyActive = this.moduleService.isIncidentsModule;

      this.isFiltersLoading = true;
    }
    this.subscriptions.add(

      this.sideFiltersService.filterTypeSelectorVMsForCurrentObjectTypes$.subscribe((filterTypes) => {
        const filters = this.allowedFiltersService.getCachedAllAllowedFilters();

        this.filters = filters.filter(
          (filter) =>
            !filter.displayForGlobalObjectType ||
            this.moduleService.currentObjectTypes.indexOf(filter.displayForGlobalObjectType) !== -1
        );
        this.filters = FilterUtilities.setFilterText(this.filters, this.localisationService, this.translateService);
        this.filterTypeSelectorViewModels = filterTypes;
        this.setFilterOptions();
        this.changeDetectorRef.markForCheck();

      })
    )
    this.subscriptions.add(
      this.sideFiltersService.favouriteFilters$.subscribe((favouriteFilters) => {
        this.favoriteFilters = favouriteFilters;
        this.checkForMatchingFavouriteFilter();
        this.changeDetectorRef.markForCheck();
      })
    )
    this.subscriptions.add(
      this.sideFiltersService.suggestedFilters$.subscribe((suggestedFilters) => {
        this.suggestedFilters = suggestedFilters;
        this.changeDetectorRef.markForCheck();
      })
    )

    this.subscriptions.add(
      this.moduleService.objectTypesChanged$
        .pipe(filter((objectTypes) => !this.currentObjectTypes.includes(objectTypes[0])))
        .subscribe(() => {
          let resetFilters = false;
          this.showOnlyActive = this.moduleService.isIncidentsModule;

          const hasRelatedFilters = FilterUtilities.ArrayContainsFiltersForObjectTypes(
            this.appliedFilters,
            this.moduleService.currentObjectTypes
          );
          const hasCommonObjTypes = this.currentObjectTypes.some((item) => this.moduleService.currentObjectTypes.includes(item));

          if (!hasCommonObjTypes && !hasRelatedFilters) {
            resetFilters = true;
          }

          this.isFiltersLoading = true;

          this.currentObjectTypes = this.moduleService.currentObjectTypes;

          if (resetFilters) {
            this.appliedFilters = [];
            this._addToAppliedFilters(this.headerFiltersCachingService.currentFilters);
            this.broadcastFiltersChanged();
          }

          this.sideFiltersService.initFilterSelectors().subscribe();
        })
    );

    this.initObjectEventEmitters();
    this._addToAppliedFilters(this.headerFiltersCachingService.currentFilters);
    this.broadcastFiltersChanged();
    this.sideFiltersService.isSideFiltersOpened$.subscribe(isOpened => {
      this.visible = isOpened;
    });

    this.headerFiltersService.currentFilters$.subscribe((filters) => {
      this.appliedFilters = filters;
      this.filterAppliedFilters();
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.headerFiltersService.setCurrentFilters([]);
    this.subscriptions.unsubscribe();
  }

  onClear(): void {
    this.appliedFilters = [];
    this.broadcastFiltersChanged();
    this.closeSideFilters();
  }

  onCancel(): void {
    this.appliedFilters = this.headerFiltersService.currentFilters;
    this.filterAppliedFilters();
    this.closeSideFilters();
    this.changeDetectorRef.detectChanges();
  }

  onApply(): void {
    this.broadcastFiltersChanged();
    this.closeSideFilters();
  }

  initObjectEventEmitters(): void {
    this.subscriptions.add(
      this.objectEventEmitters.headerFiltersExternalAdded$.subscribe((fl) => {
        this.appliedFilters = this.appliedFilters.filter(
          (s) =>
            fl.findIndex(
              (r) =>
                r.filterType === s.filterType &&
                r.displayForGlobalObjectType == s.displayForGlobalObjectType &&
                s.filterValue == r.filterValue
            ) < 0
        );

        fl.forEach((filter) => {
          let matching = this.filters.find((f) => f.filterType === filter.filterType && f.filterValue == filter.filterValue);

          if (filter.filterType === FilterTypes.Risk_RAG) {
            matching = this.allowedFiltersService
              .getCachedAllowedFiltersByType(FilterTypes.Risk_RAG)
              .find((f) => f.filterType === filter.filterType && f.filterValue == filter.filterValue);
          }
          if (filter.filterType === FilterTypes.Date) {
            matching = filter;
          }
          if (matching) {
            matching.displayForGlobalObjectType = filter.displayForGlobalObjectType;
            this.appliedFilters.push(matching);
          }
        });

        this.appliedFilters = this.appliedFilters.slice();
        this.broadcastFiltersChanged();
      })
    );

    this.subscriptions.add(
      this.objectEventEmitters.headerFiltersExternalCleared$.subscribe((res) => {
        this.onClear();
      })
    );

    this.subscriptions.add(
      this.objectEventEmitters.headerFiltersExternalChanged$.subscribe((fl) => {
        this.appliedFilters = fl.slice();
        this.broadcastFiltersChanged();
      })
    );
  }

  getIsSingleSelectHeaderFilter(objectType: ObjectTypes, filterType: FilterTypes): boolean {
    return FilterUtilities.IsHeaderFilterSingleSelect(objectType, filterType);
  }

  public onLozengeFiltersRemoved(filters: FilterViewModel[]): void {
    this.appliedFilters = this.appliedFilters.filter((f) => !filters.some((r) => r.filterType === f.filterType && f.dateProperty === r.dateProperty && r.displayForGlobalObjectType === f.displayForGlobalObjectType));
    this.filterAppliedFilters();
    this.changeDetectorRef.detectChanges();
  }


  public onLozengeFiltersTagRemoved(filters: FilterViewModel[]): void {
    this.appliedFilters = this.appliedFilters.filter((f) => !filters.some((r) => r.filterType === f.filterType && r.filterType == FilterTypes.Tag ));
    this.filterAppliedFilters();
    this.changeDetectorRef.detectChanges();
  }

  public isAccountHubUrl(): boolean {
    return this.router.url.includes('hub');
  }

  closeSideFilters(): void {
    this.sideFiltersService.toggleSideFilters('close');
  }

  public getAppliedFiltersByFilterSelector(filterSelector: FilterTypeSelectorViewModel): FilterViewModel[] {
    return this.appliedFilters.filter((f) => f.filterType === filterSelector.filterType && f.displayForGlobalObjectType === filterSelector.displayForObjectType);
  }

  public getAppliedDateFilters(filterSelector: FilterTypeSelectorViewModel): FilterViewModel[] {
    return this.appliedFilters.filter((f) =>
    f.filterType === filterSelector.filterType &&
    f.dateProperty === filterSelector.dateProperty &&
    f.displayForGlobalObjectType === filterSelector.displayForObjectType);
  }

  public onDateFilterAdded(filter: FilterViewModel, filterSelector: FilterTypeSelectorViewModel): void {
    // Remove old date filter if exists
    this.appliedFilters = this.appliedFilters.filter((f) => !(
      f.filterType === filterSelector.filterType &&
      f.dateProperty === filterSelector.dateProperty &&
      f.displayForGlobalObjectType === filterSelector.displayForObjectType));

    // Add new date filter
    this.appliedFilters.push(filter);
    this.filterAppliedFilters();
    this.changeDetectorRef.detectChanges();
  }

  public onDateFilterRemoved(filterSelector: FilterTypeSelectorViewModel): void {
    // Remove from the applied filters
    this.appliedFilters = this.appliedFilters.filter((f) => !(
    f.filterType === filterSelector.filterType &&
    f.dateProperty === filterSelector.dateProperty &&
    f.displayForGlobalObjectType === filterSelector.displayForObjectType));
    this.filterAppliedFilters();
    this.changeDetectorRef.detectChanges();
  }

  public onFiltersChangedForFilterSelectorVM(filters: FilterViewModel[]): void {
    const filtersForRemove = filters.filter((f) => f.filterAction === FilterActionTypes.Remove);
    const filtersForAdd = filters.filter((f) => f.filterAction === FilterActionTypes.Add);
    const filtersToUpdate = filters.filter((f) => f.filterAction === FilterActionTypes.Update);

    // Remove from the applied filters all filters that are not in the new filters
    if(filtersForRemove.length){
      this.appliedFilters = this.appliedFilters.filter((a) => !filtersForRemove.some((r) => r.id === a.id && ( r.filterType === FilterTypes.Tag || r.displayForGlobalObjectType === a.displayForGlobalObjectType)  ));

    }

    // Add to the applied filters all filters that are not already applied
    if(filtersForAdd.length){
      this.appliedFilters = this.appliedFilters.concat(filtersForAdd);
    }

    // Update the applied filters
    if(filtersToUpdate.length){
      // Remove Old Filters
      this.appliedFilters = this.appliedFilters.filter((a) => !filtersToUpdate.some((r) => r.id === a.id && ( r.filterType === FilterTypes.Tag || r.displayForGlobalObjectType === a.displayForGlobalObjectType)  ));
      // Add Updated Filter
      this.appliedFilters = this.appliedFilters.concat(filtersToUpdate);
    }

    // Strip all filter actions
    this.appliedFilters.forEach((f) => {
      f.filterAction = FilterActionTypes.None;
    });

    this.filterAppliedFilters();
    this.changeDetectorRef.detectChanges();
  }

  public broadcastFiltersChanged(): void {
    // Update the applied filters and broadcast the change
    this.headerFiltersService.setCurrentFilters(this.appliedFilters);

    // Update the filtered applied filters
    this.filterAppliedFilters();
    this.changeDetectorRef.detectChanges();
  }

  private setFilterOptions(): void {
    this.archiveFilterSelectorVM = this.filterTypeSelectorViewModels.find((f) => f.filterType === FilterTypes.Archived);
    this.generalFilterSelectorVMs = this.filterTypeSelectorViewModels.filter(f => f.filterType !== FilterTypes.Tag && f.filterType !== FilterTypes.Date && f.filterType !== FilterTypes.Archived);
    this.dateFiltersSelectorVMs = this.filterTypeSelectorViewModels.filter(f => f.filterType === FilterTypes.Date);
    this.initTagGroupFilters();
    this.searchByTextFilterSelectors();
    this.changeDetectorRef.detectChanges();
  }

  public searchByTextFilterSelectors(): void {
    this.filteredByTextGeneralFiltersSelectorVMs = this.generalFilterSelectorVMs.filter((f) =>
      f.filterTypeText.toLowerCase().includes(this.searchTextForFilterSelectors.toLowerCase())
    );
    this.filteredByTextGeneralFiltersSelectorVMs = this.filteredByTextGeneralFiltersSelectorVMs.filter((f) =>
     f.filterType !== FilterTypes.Chat_Message
    );

    this.filteredByTextDateFiltersSelectorVMs = this.dateFiltersSelectorVMs.filter((f) =>
      f.filterTypeText.toLowerCase().includes(this.searchTextForFilterSelectors.toLowerCase())
    );
    this.filteredByTextTagGroupFilters = this.tagGroupFilters.filter((f) =>
      f.filterText.toLowerCase().includes(this.searchTextForFilterSelectors.toLowerCase())
    );
  }

  initTagGroupFilters() {
    this.tagGroupFilters = [];
    let objTypes = this.moduleService.currentObjectTypes;
    const objectTypesWithoutTags: ObjectTypes[] = [
      ObjectTypes.Runsheet,
    ];


    if (this.filters) {
      this.tagGroupFilters = this.filters.filter(
        (s) =>
          s.filterType === FilterTypes.Tag_Group &&
          objTypes &&
          objTypes.includes(s.displayForGlobalObjectType) &&
          !objectTypesWithoutTags.includes(+s.displayForGlobalObjectType)
      );

      const newFilters = [];

      this.tagGroupFilters.forEach((f) => {
        if (f.displayForGlobalObjectType === 0 && objTypes) {
          objTypes.forEach((oType) => {
            if (!objectTypesWithoutTags.includes(oType)) {
              const newFilter: FilterViewModel = { ...f };
              newFilter.displayForGlobalObjectType = oType;
              newFilter.filterText = this.localisationService.localiseObjectType(oType) + ` ${f.filterText}`;
              newFilters.push(newFilter);
            }
          });
        }
      });

      this.tagGroupFilters = this.tagGroupFilters.filter((f) => f.displayForGlobalObjectType);
      this.tagGroupFilters = [...this.tagGroupFilters, ...newFilters];
      this.changeDetectorRef.detectChanges();
    }
  }

  public getAppliedTagFiltersForTagGroup(tagGroup: FilterViewModel): FilterViewModel[] {
    return this.appliedFilters.filter((f) => f.filterType === FilterTypes.Tag && f.relatedObjectId === tagGroup.filterValue);
  }

  public getAvailableTagFiltersForTagGroup(tagGroup: FilterViewModel): FilterViewModel[] {
    return this.filters.filter((f) => f.filterType === FilterTypes.Tag && f.relatedObjectId === tagGroup.filterValue);
  }

  public getTagGroupFilterSelectorVM(tagGroup: FilterViewModel): FilterTypeSelectorViewModel {
    return FilterUtilities.getFilterTypeSelectorViewModelByFilterType(
      this.filterTypeSelectorViewModels,
      FilterTypes.Tag,
      null,
      tagGroup.displayForGlobalObjectType
    );
  }

  private _addToAppliedFilters(filters: FilterViewModel[]): void {
    this.appliedFilters = this.appliedFilters.filter(
      (a) =>
        !filters.some(
          (r) =>
            r.filterType === a.filterType &&
            (!r.relatedObjectId || r.relatedObjectId === a.relatedObjectId) &&
            r.dateProperty === a.dateProperty &&
            r.displayForGlobalObjectType === a.displayForGlobalObjectType
        )
    );
    this.appliedFilters = this.appliedFilters.concat(filters);
  }

  public onSearchFilterSelector(searchText: string): void {
    this.searchTextForFilterSelectors = searchText;
    this.searchByTextFilterSelectors();
  }

  public filterAppliedFilters(): void {
    this.filteredAppliedFilters = FilterUtilities.GroupFiltersByObject(this.appliedFilters);
    this.filteredTagsFilters = FilterUtilities.GroupTagFiltersByTagGroup(this.filters, this.appliedFilters);
    this.checkForMatchingFavouriteFilter();
    this.changeDetectorRef.detectChanges();
  }


  public isArchivedApplied(): boolean {
    return this.appliedFilters.find((f) => f.filterType === FilterTypes.Archived)?.filterValue === 1;
  }

  public get archivedToggleOption(): { value: string; displayText: string, showArchived: boolean } {
    if(this.isArchivedApplied()){
      return this.archivedToggleOptions.find((o) => o.showArchived);
    }
    return this.archivedToggleOptions.find((o) => !o.showArchived);
  }

  public onArchivedToggle(currentState: { value: string; displayText: string, showArchived: boolean }): void {
    // Clear archived filter
    this.appliedFilters = this.appliedFilters.filter((f) => f.filterType !== FilterTypes.Archived);

    if(currentState.showArchived){
      let archivedFilter = JSON.parse(JSON.stringify(this.filters.find((f) => f.filterType === FilterTypes.Archived && f.filterValue === 1))) as FilterViewModel;
      // TODO Revise bellow
      // archivedFilter.displayForGlobalObjectType = this.archiveFilterSelectorVM.displayForObjectType;
      archivedFilter.filterDropdownTitle = this.translateService.instant(T.common.archived.many);
      this.appliedFilters.push(archivedFilter);
    }
    this.filterAppliedFilters();
  }

  public isFilterSelectorExpanded(filterSelectorText: string): boolean{
    return this.expandedFilterSelectorId === filterSelectorText;
  }

  public onExpandFilterSelector(newState: 'open' | 'close', filterSelectorText: string): void {
    if(newState === 'open'){
      this.expandedFilterSelectorId = filterSelectorText;
    } else {
      this.expandedFilterSelectorId = '';
    }
    this.changeDetectorRef.detectChanges();
  }

  private checkForMatchingFavouriteFilter(): void {
    if(this.appliedFilters.length === 0) {
      this.favouriteMatchingFilter = undefined
      return;
    }
    const currentObjectTypes = this.moduleService.currentObjectTypes;
    const favoriteFiltersForObjectTypes = this.favoriteFilters.filter((favourite) =>
      favourite.filters.every((f) => currentObjectTypes.includes(+f.displayForGlobalObjectType))
    );
    const matchingFavFiltersByLengthOfFilters = favoriteFiltersForObjectTypes.filter((favFilter) => favFilter.filters.length === this.appliedFilters.length);

    const foundFavouriteMatchingFilter = matchingFavFiltersByLengthOfFilters.find((favFilter) => {
      return favFilter.filters.every((f) =>
        this.appliedFilters.some((appliedFilter) => f.id === appliedFilter.id)
      )
    });

    this.favouriteMatchingFilter = foundFavouriteMatchingFilter;
    this.changeDetectorRef.detectChanges();
  }

  public addNewFavouriteFilter(): void {
    const initialState = {
      title: '',
      headerText: this.translateService.instant(T.common.save_filters),
      maxLength: 40,
    };
    const modalParams = Object.assign({}, this.modalConfig, { initialState });
    const modalRef = this.bsModalService.show(TitleOnlyModalComponent, modalParams);
    this.subscriptions.add(
      modalRef.content.onSave.subscribe((res: string) => {
        this.subscriptions.add(
          this.customFiltersService
          .addEmployeeCustomFilter({
            accountId: this.account.id,
            employeeId: this.employee.id,
            filters: this.appliedFilters,
            globalObjectType: ObjectTypes.Favorite_Filters,
            title: res,
            id: 0,
          })
          .subscribe((favoriteFilter) => {
            this.objectEventEmitters.broadcastObjectAdded(favoriteFilter.id, ObjectTypes.Favorite_Filters, favoriteFilter);
            void this.alertService.success(this.translateService.instant(T.common.saved_filters_updated));
            this.broadcastFiltersChanged();
          })
        )
      })
    )
  }
}

