import { IEnvironment } from '../../app/modules/shared/models/interfaces/environment.interface';
import * as baseEnv from 'src/environments/enviroment.base';

export const environment: IEnvironment = {
  ...baseEnv.environment,
  ...{
    CONFIGURATION: 'staging',
    API_URL: 'https://wetrack-stage-api.wetrack.com',
    Issuer: 'https://auth-stage.wetrack.com',
    DOMAIN: 'wetrack-stage.wetrack.com',
    MOBILE_APP_ID: 'com.wetrack.stage',
    CLUSTER_NAME:'WT-STAGE'
  }
};
