<div class="filters-backdrop" (click)="onCancel()"></div>

<div class="filters-container" [class.mobile]="mobile">
  <div class="filters-header">
     <div class="header-title">Filters</div>
     <div class="material-icons close" (click)="onCancel()">close</div>
  </div>
  <div class="filters-body">
    <div class="applied-filters">
      <app-list-search-input-fixed [placeholder]="'Search Filters'" (inputChanged)="onSearchFilterSelector($event)"></app-list-search-input-fixed>
      <div class="info-row" *ngIf="filteredAppliedFilters.size > 0">
        <span class="info-title">{{favouriteMatchingFilter ? favouriteMatchingFilter.title : (T.common.applied_filters | translate)}}</span>
        <app-button-transparent
        *ngIf="!favouriteMatchingFilter"
        [text]="T.common.save_filters | translate"
        [icon]="'bookmark_border'"
        [size]="'small'"
        (clicked)="addNewFavouriteFilter()"
        ></app-button-transparent>
      </div>
      <div class="desktop-filters-lozenges" *ngIf="filteredAppliedFilters.size > 0">
        <!-- Regular lozenges filteredFilters -->
        <ng-container *ngFor="let ft of filteredAppliedFilters | keyvalue">
          <ng-container *ngIf="ft.value[0].filterType !== filterTypesEnum.Tag">
            <app-filters-lozenge-container
              #desktopFilterLozenge
              [allowEdit]="false"
              [appliedFiltersByType]="ft.value"
              [filterTypeSelectorViewModels]="filterTypeSelectorViewModels"
              [isSingleSelect]="
                getIsSingleSelectHeaderFilter(ft.value[0].displayForGlobalObjectType, ft.value[0].filterType)
              "
              (filtersUpdated)="onLozengeFiltersRemoved($event)"
            >
            </app-filters-lozenge-container>
          </ng-container>
        </ng-container>
        <!-- Tag Lozenges -->
        <ng-container *ngFor="let ft of filteredTagsFilters | keyvalue">
          <ng-container>
            <app-filters-lozenge-container
              #desktopTagFilterLozenge
              [allowEdit]="false"
              [appliedFiltersByType]="ft.value"
              (filtersUpdated)="onLozengeFiltersTagRemoved($event)"
            >
            </app-filters-lozenge-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <!-- <app-toggle-switch [left]="'Show Archived'"></app-toggle-switch> -->
    <div class="filters-selection-container custom-scrollbar">
      <div class="archive-filter-toggle">
        <app-toggle-button
        [objects]="archivedToggleOptions"
        [currentlySelectedObject]="archivedToggleOption"
        [bindProperty]="'displayText'"
        (clicked)="onArchivedToggle($event)"
        ></app-toggle-button>
      </div>
      <div class="filters-group" *ngIf="filteredByTextGeneralFiltersSelectorVMs.length">
        <div class="group-title">General filters</div>
        <div class="group-content">
          <div *ngFor="let filterVM of filteredByTextGeneralFiltersSelectorVMs">
            <app-expandable-filter-selector
            [filterSelectorVM]="filterVM"
            [expanded]="isFilterSelectorExpanded(filterVM.filterTypeText)"
            [appliedFilters]="getAppliedFiltersByFilterSelector(filterVM)"
            (filtersUpdated)="onFiltersChangedForFilterSelectorVM($event)"
            (toggleChange)="onExpandFilterSelector($event, filterVM.filterTypeText)"
            ></app-expandable-filter-selector>
          </div>
        </div>
      </div>
      <div class="filters-group" *ngIf="filteredByTextDateFiltersSelectorVMs.length">
        <div class="group-title">Date filters</div>
        <div class="group-content">
          <div *ngFor="let filterVM of filteredByTextDateFiltersSelectorVMs">
            <app-date-expandable-filter-selector
            [filterSelectorVM]="filterVM"
            [expanded]="isFilterSelectorExpanded(filterVM.filterTypeText)"
            [appliedFilters]="getAppliedDateFilters(filterVM)"
            (filterAdded)="onDateFilterAdded($event, filterVM)"
            (filterRemoved)="onDateFilterRemoved(filterVM)"
            (toggleChange)="onExpandFilterSelector($event, filterVM.filterTypeText)"
            ></app-date-expandable-filter-selector>
          </div>
        </div>
      </div>
      <div class="filters-group" *ngIf="filteredByTextTagGroupFilters.length">
        <div class="group-title">Tags filters</div>
        <div class="group-content">
          <div *ngFor="let tagGroup of filteredByTextTagGroupFilters">
            <app-tag-expandable-filter-selector
            [tagGroupFilter]="tagGroup"
            [expanded]="isFilterSelectorExpanded(tagGroup.filterText)"
            [filterSelectorVM]="getTagGroupFilterSelectorVM(tagGroup)"
            [appliedFilters]="getAppliedTagFiltersForTagGroup(tagGroup)"
            [availableTagFilters]="getAvailableTagFiltersForTagGroup(tagGroup)"
            (filtersUpdated)="onFiltersChangedForFilterSelectorVM($event)"
            (toggleChange)="onExpandFilterSelector($event, tagGroup.filterText)"
            ></app-tag-expandable-filter-selector>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="isIOS ? 'ios-footer' : isAndroid ? 'android-footer' : ''" class="filters-footer">
    <div class="left-side">
      <app-button-transparent [text]="'Reset all filters'" [icon]="'clear_all'" [size]="'small'" (click)="onClear()"></app-button-transparent>
    </div>
    <div class="right-side">
      <app-button-transparent [text]="'Cancel'" [size]="'small'" (click)="onCancel()"></app-button-transparent>
      <app-button-rectangle class="ms-3" [text]="'Apply'" (click)="onApply()"></app-button-rectangle>
    </div>
  </div>
</div>
